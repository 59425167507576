import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { format } from "date-fns"
import { Helmet } from "react-helmet"
import "./article.css"
import Image from "../components/image"
import Markdown from "react-markdown"

const ARTICLEPAGE = ({ data, pageContext }) => {
  const { metaData } = data.strapiBlog
  const global = data.strapiGlobal
  console.log(data.strapiBlog)
  return (
    <div>
      <SEO seo={metaData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext }}>
        <div className="container">
          <Helmet>
            <title>{data.strapiBlog.metaData?.metaTitle}</title>
            <meta
              name="description"
              content={data.strapiBlog.metaData?.metaDescription}
            />
            <meta
              name="twitterCardType"
              content={data.strapiBlog.metaData?.twitterCardType}
            />
            <meta
              name="twitterUsername"
              content={data.strapiBlog.metaData?.twitterUsername}
            />
            <meta
              name="og:description"
              content={data.strapiBlog.metaData?.metaDescription}
            />
            <meta
              name="twitter:description"
              content={data.strapiBlog.metaData?.metaDescription}
            />
          </Helmet>

          <div className="img-blog">
            <Image className="blog-image2" media={data.strapiBlog.heroImage} />
          </div>
          <span className="header-label">{data.strapiBlog.title}</span>
          <hr
            className="my-3 md:my-3 hr hr-article"
            style={{
              borderTop: "8px solid var(--evp-primary)",
              width: "46px",
            }}
          ></hr>
          <div className="sub-heading-container">
            <div className="sub-heading">
              <span>{data.strapiBlog.author}</span>
              <span>
                {format(new Date(data.strapiBlog.created_at), "MMMM do, yyyy")}
              </span>
            </div>
            <div className="icons">
              <span></span>
            </div>
          </div>
          <div className="article-final">
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: data.strapiBlog.content }}
              />
              {/* {data.strapiBlog.content}
                <Markdown source={data.strapiBlog.content} /> */}
            </div>
            <NextArticleCard curId={data.strapiBlog.id}></NextArticleCard>
            {/* {this.state.next ? (
                <a href={`/app/${this.state.next.slug}`}>
                  <div className="article-final-ads">
                    <span>Read Next</span>
                    <img src={this.state.next.image.url}></img>
                    <span>{this.state.next.title}</span>
                  </div>
                </a>
              ) : (
                ""
              )} */}
          </div>
        </div>
      </Layout>
    </div>
  )
}

class NextArticleCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      curId: props.curId,
      slug: props.articleSlug,
      next: null,
    }
  }
  componentDidMount() {
    const setState = this.setState.bind(this)
    // const endpoint = `https://cms.evplugs.co.in/blogs?id=${
    //   parseInt((this.props.curId).split('_')[1])+1
    // }`
    const nextId=parseInt((this.props.curId).split('_')[1])
    const endpoint = `https://cms.evplugs.co.in/blogs?[id_gt]=${nextId}&[status]=published`
    console.log(this.props.curId)

    fetch(endpoint, {
      method: "GET",
    })
      .then(resp => resp.json())
      .then(function (response) {
        console.log("here", response)
        setState({ next: response[0] })
      })
  }

  render() {
    return (
      <>
        {this.state.next ? (
          <a href={`/blogs/${this.state.next.slug}`}>
            <div >
              <span className="next-head">Read Next</span>
              <img className="next-blog" src={this.state.next.image.url}></img>
              <div className="next-textdiv"><span className="next-text">{this.state.next.title}</span></div>
            </div>
          </a>
        ) : (
          ""
        )}
      </>
    )
  }
}

export default ARTICLEPAGE

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    footer {
      id
      columns {
        id
        links {
          id
          newTab
          text
          url
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        title
      }
      id
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      smallText
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      shareImage {
        localFile {
          publicURL
        }
      }
    }
    navbar {
      button {
        id
        newTab
        text
        type
        url
      }
      id
      links {
        url
        text
        newTab
        id
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    notificationBanner {
      id
      text
      type
    }
  }

  query DynamicBlogQuery($id: String!) {
    strapiGlobal {
      ...GlobalData
    }
    strapiBlog(id: { eq: $id }) {
      id
      slug
      shortName
      title
      image {
        url
      }
      heroImage {
        url
      }
      author
      created_at(formatString: "MMM DD YYYY")
      metaData {
        metaTitle
        metaDescription
      }
      content
    }
  }
`
